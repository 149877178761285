<template>
  <div>

    <van-form style="margin: 20px" @submit="onSubmit">

      <h2 style="text-align: center">慈善困难群众临时救助申请</h2>

      <van-field
          v-model="S_name"
          rows="1"
          autosize
          label="受助人姓名"
          type="textarea"
          placeholder="请输入受助人姓名"
          :rules="[{ required: true, message: '请输入受助人姓名' }]"
      />
      <van-field
          v-model="S_sfz"
          rows="1"
          autosize
          label="受助人身份证号码"
          type="textarea"
          placeholder="请输入受助人身份证号码"
          @blur="change()"
          :rules="[{ required: true, message: '请输入受助人身份证号码' }]"
      />
      <van-field
          v-model="S_xb"
          rows="1"
          autosize
          label="受助人性别"
          type="textarea"
          placeholder="请输入受助人性别"
          :rules="[{ required: true, message: '请输入受助人性别' }]"
      />

      <van-field
          v-model="S_age"
          rows="1"
          autosize
          label="受助人年龄"
          type="textarea"
          placeholder="请输入受助人年龄"
          :rules="[{ required: true, message: '请输入受助人年龄' }]"
      />
      <van-field
          v-model="H_name"
          rows="1"
          autosize
          label="户主姓名"
          type="textarea"
          placeholder="请输入户主姓名"
          :rules="[{ required: true, message: '请输入户主姓名' }]"
      />
      <van-field
          v-model="H_sfz"
          rows="1"
          autosize
          label="户主身份证号码"
          type="textarea"
          placeholder="请输入户主身份证号码"
          @blur="change1()"
          :rules="[{ required: true, message: '请输入户主身份证号码' }]"
      />
      <van-field
          v-model="H_xb"
          rows="1"
          autosize
          label="户主性别"
          type="textarea"
          placeholder="请输入户主性别"
          :rules="[{ required: true, message: '请输入户主性别' }]"
      />

      <van-field
          v-model="H_age"
          rows="1"
          autosize
          label="户主年龄"
          type="textarea"
          placeholder="请输入户主年龄"
          :rules="[{ required: true, message: '请输入户主年龄' }]"
      />
      <van-field
          v-model="url"
          rows="1"
          autosize
          label="家庭住址"
          type="textarea"
          placeholder="请输入家庭住址"
          :rules="[{ required: true, message: '请输入家庭住址' }]"
      />
      <van-field
          readonly
          clickable
          name="picker"
          :value="hkxz"
          label="户口性质"
          placeholder="请选择户口性质"
          @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>


      <van-field
          readonly
          clickable
          name="picker"
          :value="knhlx"
          label="困难户类别"
          placeholder="请选择困难户类别"
          @click="showPickerknhlx = true"
      />
      <van-popup v-model="showPickerknhlx" position="bottom">
        <van-picker
            show-toolbar
            :columns="columnsknhlx"
            @confirm="onConfirmknhlx"
            @cancel="showPickerknhlx = false"
        />
      </van-popup>
      <van-field
          v-model="qjrk"
          rows="1"
          autosize
          label="全家人口"
          type="textarea"
          placeholder="请输入全家人口"
          :rules="[{ required: true, message: '请输入全家人口' }]"
      />
      <van-field
          v-model="phone"
          rows="1"
          autosize
          label="联系电话"
          type="textarea"
          placeholder="请输入联系电话"
          :rules="[{ required: true, message: '请输入联系电话' }]"
      />
      <van-field
          v-model="yhzh"
          rows="1"
          autosize
          label="银行"
          type="textarea"
          placeholder="请输入银行"
          :rules="[{ required: true, message: '请输入银行' }]"
      />

      <van-field
          v-model="zh"
          rows="1"
          autosize
          label="账户"
          type="textarea"
          placeholder="请输入账户"
          :rules="[{ required: true, message: '请输入账户' }]"
      />
      <van-field
          v-model="cont"
          rows="1"
          autosize
          label="申请理由"
          type="textarea"
          placeholder="请输入申请理由"
          :rules="[{ required: true, message: '请输入申请理由' }]"
      />
      <van-field name="uploader" label="申请表上传">
        <template #input>
          <el-upload
              class="upload-demo"
              ref="File"
              :action="action"
              :data="data"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :on-success="handleAvatarSuccess"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </template>
      </van-field>


      <van-field name="uploader" label="身份证上传">
        <template #input>
          <el-upload
              class="upload-demo"
              ref="File"
              :action="action"
              :data="data"
              :on-preview="handlePreview1"
              :on-remove="handleRemove1"
              :before-remove="beforeRemove1"
              :on-success="handleAvatarSuccess1"
              multiple
              :limit="3"
              :on-exceed="handleExceed1"
              :file-list="fileList1">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </template>
      </van-field>

      <van-field name="uploader" label="其他资料上传">
        <template #input>
          <el-upload
              class="upload-demo"
              ref="File"
              :action="action"
              :data="data"
              :on-preview="handlePreview2"
              :on-remove="handleRemove2"
              :before-remove="beforeRemove2"
              :on-success="handleAvatarSuccess2"
              multiple
              :limit="3"
              :on-exceed="handleExceed2"
              :file-list="fileList2">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </template>
      </van-field>

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">发布</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "index",
  methods:{

  }
}
</script>

<style scoped>
  .body{
    margin-top: 50px;
  }
</style>